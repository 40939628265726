import { Error as ErrorIcon } from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps, Theme } from '@mui/material';
import { SxProps, SystemStyleObject } from '@mui/system';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

const textFieldStyles: SystemStyleObject<Theme> = {
  color: 'secondary.main',
  '& input': { minHeight: '2.5rem', height: '100%', py: 0 },
};

const labelStyles: SxProps<Theme> = ({ palette }) => ({
  color: palette.secondary.main,
  '&.Mui-focused': { color: palette.secondary.main },
});

interface FormTextFieldProps
  extends Pick<TextFieldProps, 'label' | 'sx' | 'onChange' | 'disabled' | 'placeholder'>,
    FieldProps {
  label: string;
  type?: 'text' | 'password' | 'textarea';
}

function FormTextField({ field, form, label, type = 'text', disabled, placeholder, sx }: FormTextFieldProps) {
  const { t } = useTranslation();
  const errorMessage = form?.touched?.[field?.name!] && (form?.errors?.[field?.name!] as string | undefined);
  const hasError: boolean = !!errorMessage;
  const isTextarea: boolean = type === 'textarea';

  return (
    <TextField
      label={t(label)}
      margin="none"
      variant="standard"
      size="small"
      fullWidth
      sx={sx}
      error={hasError}
      helperText={errorMessage ? t(errorMessage) : null}
      multiline={isTextarea}
      rows={isTextarea ? 2.5 : undefined}
      InputLabelProps={{ shrink: true, sx: labelStyles }}
      InputProps={{
        sx: textFieldStyles,
        endAdornment: hasError && (
          <InputAdornment position="end">
            <ErrorIcon color="error" sx={{ width: '20px' }} />
          </InputAdornment>
        ),
      }}
      {...field}
      disabled={disabled}
      type={type}
      placeholder={placeholder}
    />
  );
}

export default FormTextField;
