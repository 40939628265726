import { Typography, TypographyProps } from '@mui/material';
import { parsedPhone } from '../../data/plexoContactData';

function PhoneNumber({ phone, ...typographyProps }: TypographyProps<'a'> & { phone: string }) {
  const renderedPhone = parsedPhone(phone);

  return (
    <Typography
      component="a"
      href={`tel:${phone}`}
      className="contact-link"
      variant="body1"
      color="secondary.main"
      {...typographyProps}
    >
      {renderedPhone}
    </Typography>
  );
}

export default PhoneNumber;
