import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  MenuItem,
  MenuItemProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PluginTier } from '../../data/plugins';
import FileDownload from '../common/icons/FileDownload';

const dialogTitleStyles: SxProps = {
  paddingInline: 4.25,
  paddingBlockStart: 4.25,
  paddingBlockEnd: 3,
  textAlign: 'center',
  fontSize: '1.75rem',
};

const pluginText = 'resources.plugins';

function PluginTiersDialogTrigger({
  children,
  plugin,
  pluginType,
  ...menuItemProps
}: MenuItemProps & { plugin: PluginTier[]; pluginType: 'woocommerce' }) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const dialogType = `${pluginText}.dialog-${pluginType}`;

  const tiers: Array<{ features: string[] }> = t(`${dialogType}.tiers`, { returnObjects: true });

  const features = tiers.map((tier) => tier.features);

  return (
    <>
      <MenuItem {...menuItemProps} onClick={() => setOpenDialog(true)}>
        {children}
      </MenuItem>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="xl"
        sx={{ [`.${dialogClasses.paper}`]: { borderRadius: 2 } }}
      >
        <DialogTitle sx={dialogTitleStyles}>{t(`${dialogType}.title`)}</DialogTitle>
        <DialogContent sx={{ paddingInline: 4.25, paddingBlock: 0 }}>
          <Box component="ol" sx={ulStyles}>
            {plugin.map((tier, index) => {
              return (
                <PluginTierListItem
                  key={tier.tierName}
                  {...tier}
                  dialogType={dialogType}
                  features={features[index]}
                  index={index}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ paddingInline: 4.25, paddingBlockStart: 3, paddingBlockEnd: 3.75, justifyContent: 'center' }}
        >
          <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
            {t(`cancel`)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const ulStyles: SxProps<Theme> = ({ palette }) => ({
  color: palette.secondary.main,
  display: 'grid',
  gap: '1.25rem',
  gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
});

const listItemStyles: SxProps = {
  border: '1px solid #DAE3FE',
  listStyle: 'none',
  padding: 2.5,
  borderRadius: 1.25,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
};

const dlStyles: SxProps = {
  display: 'grid',
  gap: 1.25,
  marginBlockEnd: 3.5,
};

function PluginTierListItem({
  tierName,
  href,
  requestMethod,
  filename,
  pricingDisclaimer,
  dialogType,
  index,
  features,
}: PluginTier & { dialogType: string; features: string[]; index: number }) {
  const { t } = useTranslation();

  return (
    <Box component="li" sx={listItemStyles}>
      <Box component="dl" sx={dlStyles}>
        <Box component="dt" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" color="inherit">
            Plugin {tierName}
          </Typography>
          {pricingDisclaimer && <Chip label={pricingDisclaimer} sx={{ color: 'inherit' }} />}
        </Box>
        {features.map((feature) => (
          <dd className="tier-item" key={feature}>
            {t(feature)}
          </dd>
        ))}
      </Box>
      <Button
        component="a"
        href={href}
        variant="contained"
        color="primary"
        sx={{ gap: 1.25 }}
        {...(requestMethod === 'file' && { download: filename, endIcon: <FileDownload /> })}
      >
        {t(
          requestMethod === 'file' ? `${pluginText}.menu-option-plugin` : `${dialogType}.tiers.${index}.cta`,
        )}
      </Button>
    </Box>
  );
}

export default PluginTiersDialogTrigger;
