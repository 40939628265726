import { Box, Divider, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import colors from '../data/colors';
import { plexoContactData } from '../data/plexoContactData';
import PhoneNumber from './common/PhoneNumber';

const sectionContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: { xs: 'flex-start', md: 'flex-end' },
  fontStyle: 'normal',
  zIndex: 1,
  textAlign: { xs: 'center', md: 'start' },
};

const contactInfo: SxProps = {
  borderLeft: { xs: 'unset', md: `3px solid ${colors.skyblue.transparent}` },
  paddingTop: '1rem',
  paddingLeft: { xs: 'unset', md: '3rem' },
  marginLeft: { xs: 'unset', md: '3rem' },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  '& p': { fontFamily: 'Ubuntu', fontSize: '1rem' },
  alignItems: { xs: 'center', md: 'unset' },
  marginBottom: { xs: '2.5rem', md: 'unset' },
};

const contactInfoTitle: SxProps = {
  fontWeight: '700',
  fontFamily: 'Ubuntu',
  fontSize: { xs: '1.4375rem', md: '1.5625rem' },
  textAlign: { xs: 'center', md: 'start' },
};

const contactInfoSubTitle: SxProps = {
  color: colors.darkGreen,
  fontWeight: '500',
  fontSize: '1.125rem',
};

const sectionDivider: SxProps = {
  backgroundColor: colors.skyblue.lightTransparent,
  width: 'auto',
  marginX: '-3rem',
  marginY: '2.5rem',
};

const phoneWrappers: SxProps = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
};

const dlWrapper: SxProps = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', md: 'auto 1fr' },
  gap: { xs: '1rem', md: '1.5rem' },
};

const contact: string = 'contact';
const viewBanner: string = `${contact}.viewbanner`;
const contactInfoText: string = `${viewBanner}.contact-info`;
const phoneText = `${contactInfoText}.phone`;
const emailText = `${contactInfoText}.email`;

function ContactInfo() {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box component="address" sx={sectionContainer}>
        {isMobile && <Divider sx={sectionDivider} />}
        <Box sx={contactInfo}>
          <Typography component="h2" variant="h5" sx={contactInfoTitle}>
            {t(`${phoneText}.title`)}
          </Typography>
          <Box sx={dlWrapper}>
            <dl>
              <Typography component="dt" variant="body1" sx={contactInfoSubTitle}>
                {t(`${phoneText}.subtitle-1`)}
              </Typography>
              {plexoContactData.strategicAliances.map((phone) => (
                <dd key={phone}>
                  <PhoneNumber phone={phone} />
                </dd>
              ))}
            </dl>
            <dl>
              <Typography component="dt" variant="body1" sx={contactInfoSubTitle}>
                {t(`${phoneText}.subtitle-2`)}
              </Typography>
              <Box sx={phoneWrappers}>
                {plexoContactData.commercial.map((phone) => (
                  <dd key={phone}>
                    <PhoneNumber phone={phone} />
                  </dd>
                ))}
              </Box>
            </dl>
          </Box>
          <Typography component="h2" variant="h5" sx={contactInfoTitle}>
            {t(`${emailText}.title`)}
          </Typography>
          <dl>
            <Typography component="dt" variant="body1" sx={contactInfoSubTitle}>
              {t(`${emailText}.subtitle-1`)}
            </Typography>
            {plexoContactData.emails.map((email) => (
              <dd key={email}>
                <Typography
                  component="a"
                  variant="body1"
                  href={`mailto:${email}`}
                  color="secondary.main"
                  className="contact-link"
                >
                  {email}
                </Typography>
              </dd>
            ))}
          </dl>
        </Box>
      </Box>
    </>
  );
}

export default ContactInfo;
