import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  SxProps,
  Typography,
} from '@mui/material';
import { KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import colors from '../../data/colors';
import { Plugin } from '../../data/plugins';
import ChevronDown from '../common/icons/ChevronDown';
import CloudDownload from '../common/icons/CloudDownload';
import FileDownload from '../common/icons/FileDownload';
import PluginTiersDialogTrigger from './PluginTiersDialogTrigger';

const imgStyle: SxProps = {
  maxWidth: '114px',
  maxHeight: '114px',
  borderRadius: '50%',
  boxShadow: `0 3px 6px ${colors.blackShadow}`,
};

const menuInlineSize: SxProps = { minInlineSize: { xs: '13.5rem', md: '14rem' } };

const buttonStyle: SxProps = {
  ...menuInlineSize,
  justifyContent: 'space-between',
  bgcolor: colors.teal.dark,
  borderColor: colors.teal.dark,
  '&:hover': {
    bgcolor: colors.teal.resourcesCard,
    borderColor: colors.teal.resourcesCard,
  },
};

const menuStyle: SxProps = {
  width: '100%',
  mt: '0.5rem',
  borderRadius: '21px',
  boxShadow: `0 3px 6px ${colors.blueShadow}`,
  bgcolor: 'primary.light',
  px: '1.25rem',
  boxSizing: 'border-box',
};

const menuItemStyle: SxProps = {
  display: 'flex',
  gap: '0.75rem',
  color: 'secondary.main',
  px: 0,
  pt: '0.75rem',
  borderColor: 'secondary.main',
};

const PluginCard = ({ img, ...pluginProps }: Plugin) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.875rem' }}>
      <Box component="img" src={img || ''} sx={imgStyle} />
      <Typography variant="h3" color="secondary.main">
        <Trans i18={pluginProps.title} />
      </Typography>
      <PluginTrigger {...pluginProps} />
    </Box>
  );
};

const pluginText = 'resources.plugins';

function useDropdown() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => setOpenMenu((previousState: boolean) => !previousState);

  const handleClose = (event: Event | SyntheticEvent) =>
    !anchorRef.current?.contains(event.target as HTMLElement) ? setOpenMenu(false) : undefined;

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (['Tab', 'Escape'].includes(event.key)) {
      event.preventDefault();
      setOpenMenu(false);
    }
  };

  // return focus to the button when transition from !open -> open
  const previousOpenRef = useRef(openMenu);

  useEffect(() => {
    if (previousOpenRef.current && !openMenu) {
      anchorRef.current!.focus();
    }

    previousOpenRef.current = openMenu;
  }, [openMenu]);

  return {
    openMenu,
    anchorRef,
    handleToggle,
    handleClose,
    handleListKeyDown,
  };
}

function PluginTrigger({ title, manual, plugin }: Omit<Plugin, 'img'>) {
  const { t } = useTranslation();
  const { openMenu, anchorRef, handleToggle, handleClose, handleListKeyDown } = useDropdown();

  const triggerButtonId = `trigger-button-${title}`;
  const menuId = `menu-${title}`;

  return (
    <>
      <Button
        ref={anchorRef}
        id={triggerButtonId}
        variant="contained"
        color="primary"
        aria-controls={openMenu ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleToggle}
        endIcon={<ChevronDown />}
        sx={buttonStyle}
      >
        {t(`${pluginText}.button`)}
      </Button>
      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={menuInlineSize}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: `left ${placement === 'bottom-start' ? 'top' : 'bottom'}`,
            }}
          >
            <Paper sx={menuStyle}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={openMenu}
                  id={menuId}
                  aria-labelledby={triggerButtonId}
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    component="a"
                    download
                    href={manual.href}
                    onClick={handleClose}
                    sx={menuItemStyle}
                  >
                    {t(`${pluginText}.menu-option-manual`)}
                    <FileDownload color={colors.blue} />
                  </MenuItem>
                  <PluginPackageMenuItem plugin={plugin} onClick={handleClose} sx={menuItemStyle} />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function PluginPackageMenuItem({ plugin, ...menuItemProps }: Pick<Plugin, 'plugin'> & MenuItemProps<'a'>) {
  const { t } = useTranslation();

  const buttonText = t(`${pluginText}.menu-option-plugin`);
  const buttonIcon = <CloudDownload />;
  const buttonChildren = (
    <>
      {buttonText}
      {buttonIcon}
    </>
  );

  if (Array.isArray(plugin)) {
    return (
      <PluginTiersDialogTrigger
        title={buttonText}
        plugin={plugin}
        sx={menuItemProps.sx}
        pluginType="woocommerce"
      >
        {buttonChildren}
      </PluginTiersDialogTrigger>
    );
  }
  return (
    <MenuItem component="a" download href={plugin.href} {...menuItemProps}>
      {buttonChildren}
    </MenuItem>
  );
}

export default PluginCard;
