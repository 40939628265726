export const plexoContactData = {
  strategicAliances: ['+59895501741'],
  commercial: ['+59897970481', '+59897222241', '+59897885323', '+59893850085'],
  emails: ['comercial@plexo.com.uy'],
} as const;

export const parsedPhone = (phone: string) => {
  const areaCode = phone.slice(0, 4);
  const numberFirstChunk = phone.slice(4, 6);
  const numberSecondChunk = phone.slice(6, 9);
  const numberLastChunk = phone.slice(9);

  return `(${areaCode}) ${numberFirstChunk} ${numberSecondChunk} ${numberLastChunk}`;
};
