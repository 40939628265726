import Magento from '../assets/img/logo-magento@2x.png';
import Prestashop from '../assets/img/logo-prestashop@2x.png';
import Woo from '../assets/img/logo-woo@2x.png';
import MagentoManualPdf from '../assets/plugins/magento/Plexo-Manual-Plugin-Magento.pdf';
import MagentoPlugin from '../assets/plugins/magento/plexo-plugin-magento.zip';
import PrestashopManualPdf from '../assets/plugins/prestashop/Plexo-Manual-Plugin-PrestaShop.pdf';
import PrestashopPlugin from '../assets/plugins/prestashop/plexo-plugin-prestashop.zip';
import WooCommerceManualPdf from '../assets/plugins/woocommerce/Plexo-Manual-Plugin-WooCommerce.pdf';
import WooCommercePluginTierStarter from '../assets/plugins/woocommerce/woocommerce-plexo-checkout-gateway-v-2.0.4.zip';
import WooCommercePluginTierStandard from '../assets/plugins/woocommerce/woocommerce-plexo-checkout-gateway-v-2.2.4.zip';
import { PlexoEmail } from './urls';

export interface Plugin {
  title: string;
  img: string;
  manual: FileProps;
  plugin: FileProps | PluginTier[];
}

export interface PluginTier extends FileProps {
  requestMethod: 'file' | 'email';
  tierName: string;
  pricingDisclaimer?: string;
}

export interface FileProps {
  href: string;
  filename?: string;
}

export const plugins: Plugin[] = [
  {
    title: 'Woo Commerce',
    img: Woo,
    manual: { href: WooCommerceManualPdf },
    plugin: [
      { tierName: 'Starter', requestMethod: 'file', href: WooCommercePluginTierStarter },
      { tierName: 'Standard', requestMethod: 'file', href: WooCommercePluginTierStandard },
      {
        tierName: 'Premium',
        requestMethod: 'email',
        href: `mailto:${PlexoEmail.BUSINESS}?subject=Plugin WooCommerce Premium`,
        pricingDisclaimer: 'Pricing: 1% + IVA',
      },
    ],
  },
  {
    title: 'Magento',
    img: Magento,
    manual: { href: MagentoManualPdf },
    plugin: { href: MagentoPlugin },
  },
  {
    title: 'PrestaShop',
    img: Prestashop,
    manual: { href: PrestashopManualPdf },
    plugin: { href: PrestashopPlugin },
  },
];
